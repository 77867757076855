:root {
  --body-bg-color: #1d1f26;
  --bg-color: #363946;
  --text-color: #d3d3d3;
  --text-second-color: hsl(236, 9%, 61%);
  --border-color: #4b4d59;
  --button-bg-color: #4b4d59;
}
body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

#root {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: var(--body-bg-color);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
}

@media screen and (max-width: 1170px) {
  .container {
    box-sizing: border-box;
    margin: 0 50px;
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow: hidden;
  }
}
