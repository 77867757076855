.button {
  padding: 0.5em 1em;
  background-color: var(--button-bg-color);
  color: var(--text-color);
  border: 0;
  font-size: clamp(1em, 2vw, 18px);
  border-radius: 5px;
  cursor: pointer;
}
.button:hover {
  color: var(--text-color);
  background-color: var(--body-bg-color);
}

.button-absolute {
  position: absolute;
  right: -1em;
  top: -1em;
}

@media screen and (max-width: 768px) {
  .button-absolute {
    right: 1em;
    top: 1em;
  }
}
@media screen and (max-width: 576px) {
  .button-absolute {
    right: 1em;
    top: 3em;
  }
}
