.reader-input {
  margin-bottom: 1em;
  flex-basis: min(700px, 70%);
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 2px solid var(--border-color);
}

.reader-input > .reader-input__content {
  width: 80%;
}

.number-input {
  width: 100px;
  box-sizing: border-box;
  margin-bottom: 0.5em;
  padding: 0 0 0 1em;
  display: block;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.text-input {
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  padding: 1em;
  font-size: 18px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.button-container {
  margin: 0.5em 0;
  display: flex;
  justify-content: space-around;
  gap: 30px;
}
