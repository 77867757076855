.timer {
  text-align: center;
  position: absolute;
  top: -5em;
  background-color: var(--bg-color);
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: clamp(1em, 1vw, 2em);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .timer {
    position: static;
  }
}
