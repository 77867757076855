.autoplay-controllers {
  position: absolute;
  bottom: 2em;
  left: 50%;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  transform: translateX(-50%);
}

.autoplay-speed {
  flex-basis: 100%;
  margin-top: 1em;
}

@media screen and (max-width: 576px) {
  .autoplay-controllers {
    bottom: 5em;
  }
}
