.reader {
  position: relative;
  box-sizing: border-box;
  padding: 6em 2em;
  min-width: 700px;
  max-width: calc(100vw - 50px);
  min-height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 5px;
  text-align: center;
}

.reader__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reader-text {
  font-size: 2em;
  margin: 1rem 0;
}

@media screen and (max-width: 768px) {
  .reader {
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
  }
}
