.fontsize-controllers {
  position: absolute;
  top: 1em;
  left: 50%;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 10px;
  flex-wrap: wrap;
  transform: translateX(-50%);
}

.fontsize-text {
  flex-basis: 100%;
  margin-bottom: 1em;
}

@media screen and (max-width: 576px) {
  .fontsize-controllers {
    top: 5em;
  }
}
